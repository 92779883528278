import { Organization } from '../../interfaces';
import { CreatorsPortalSupportedLanguages, PlatformsV2 } from '../../enums';

export interface CommunityOverview {
  activeMembersCount: number;
  pendingApplicantsCount: number;
  approvedApplicantsCount: number;
  rejectedApplicantsCount: number;
}

export interface CommunityLandingPageConfiguration {
  image: {
    url: string;
  };
  content: {
    mainTitle: string;
    mainBody: string;
    subTitle: string;
    bulletListItems: string[];
    primaryButtonText: string;
    note: string;
    loginLabel: string;
  };
}

export enum CommunityDefaultThemeColoursEnum {
  BACKGROUND = '#F5F6F7',
  BUTTON = '#D9D9D9',
  BUTTON_TEXT = '#FFFFFF',
  TITLE_TEXT = '#151A30',
  BODY_TEXT = '#2E3A59',
}

export interface InputControl {
  label: string;
  isEnabled: boolean;
  isMandatory: boolean;
}

export interface CommunityApplicationPageConfiguration {
  image: {
    url: string;
  };
  isCustomFieldNameEnabled?: boolean;
  content: {
    firstName: InputControl;
    lastName: InputControl;
    gender: InputControl;
    birthDate: InputControl;
    phoneNumber: InputControl;
    email: InputControl;
    shippingAddress: InputControl;
    paypalEmail: InputControl;
    instagram: InputControl;
    facebook: InputControl;
    youtube: InputControl;
    tiktok: InputControl;
    twitter: InputControl;
  };
}

export interface CommunityThemeColours {
  background: string;
  button: string;
  buttonText: string;
  titleText: string;
  bodyText: string;
}

export interface CommunityConfiguration<T = string> {
  id?: string;
  organization?: T extends CommunityWithOrganization ? Organization : string;
  clientId?: string;
  revisionNumber?: null | 99999;
  language?: CreatorsPortalSupportedLanguages;
  themeColours?: CommunityThemeColours;
  isPublished?: boolean;
  landingPage?: CommunityLandingPageConfiguration;
  applicationPage?: CommunityApplicationPageConfiguration;
}

export enum CommunityConfigurationStepEnum {
  LANDING_PAGE = 'LANDING-PAGE',
  APPLICATION_PAGE = 'APPLICATION-PAGE',
}

export enum CommunityAssetsStepEnum {
  COMMUNITY_LANDING_PAGE = 'community-landing-page',
  COMMUNITY_APPLICATION_PAGE = 'community-application-page',
}

export enum CommunityAssetsFieldEnum {
  COMMUNITY_IMAGES = 'community-images',
}

export interface CommunityApplicantShippingAddress {
  country: string;
  state: string;
  zip: string;
  city: string;
  address: string;
}

export enum CommunityApplicantGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON-BINARY',
  OTHERS = 'OTHERS',
  I_PREFER_NOT_TO_SAY = 'I PREFER NOT TO SAY',
}

export interface CommunityApplicantInfo {
  id?: string;
  firstName: string;
  lastName?: string;
  email: string;
  gender?: CommunityApplicantGenderEnum;
  birthDate?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  phoneNumber?: string;
  shippingAddress?: CommunityApplicantShippingAddress;
  paypalEmail?: string;
  instagramUsername?: string;
  facebookUsername?: string;
  youtubeUsername?: string;
  tiktokUsername?: string;
  twitterUsername?: string;
}

export type CommunityWithOrganization = 'COMMUNITY_WITH_ORGANIZATION';
export type CommunityWithApplicant = 'COMMUNITY_WITH_APPLICANT';

export enum CommunityApplicationStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export interface CommunityApplication<T1 = string, T2 = string> {
  _id: string;
  organization: T1 extends CommunityWithOrganization ? Organization : string;
  applicant: T2 extends CommunityWithApplicant ? CommunityApplicantInfo : string;
  status?: CommunityApplicationStatusEnum;
  createdAt: Date;
  updatedAt: Date;
  eventsTimestamp?: {
    deletedAt?: Date;
    approvedAt?: Date;
    rejectedAt?: Date;
  };
}

export interface ShippingAddress {
  country: string;
  state: string;
  zip: string;
  city: string;
  address: string;
}

export interface SocialPlatform {
  isMandatory: boolean;
  isEnabled: boolean;
  platform: string;
  username: string;
  pk: string;
  profilePictureUrl: string;
  stats?: { key: string; value: string | number }[];
}
export interface ActiveCampaign {
  influencerId: string;
  campaignSlugName: string;
  campaignName: string;
  clientId: string;
  platform: PlatformsV2;
  to: Date;
  from: Date;
  sharedWith: boolean;
}

export interface Application extends CommunityApplication<string, CommunityWithApplicant> {
  facebook?: SocialPlatform;
  instagram?: SocialPlatform;
  youtube?: SocialPlatform;
  twitter?: SocialPlatform;
  tiktok?: SocialPlatform;
  campaigns?: ActiveCampaign[];
}
export interface ApplicationApiRes {
  data: Application[];
  totalDocument: number;
}
